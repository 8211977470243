import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import heliXLogo from "../resources/logo60.png";


export default function Header() {


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
          <Toolbar style={{ display: 'grid', justifyContent: 'center'}}>
            <img src={heliXLogo} alt="Heli-X" style={{ padding: 10 }}></img>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, textAlign: 'center'}}>
            Multiplayer Server
            </Typography>
          </Toolbar>
       
      </AppBar>
    </Box>
  );
}

